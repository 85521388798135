/* Needed styles to display a tooltip over disabled Autocomplete options,
these styles couldn't be achieved using MUI styling */
.MuiAutocomplete-listbox li[aria-disabled='true'] {
  pointer-events: inherit !important;
}

.MuiAutocomplete-listbox li[aria-disabled='true']:hover,
.MuiAutocomplete-listbox li[aria-disabled='true']:focus {
  background: white !important;
}

.MuiAutocomplete-listbox li[aria-disabled='true']:active {
  background: white !important;
  pointer-events: none !important;
}
